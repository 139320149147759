import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { clearSession } from '../network/service'

Vue.use(VueRouter)
//解决点击路由重复的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 原版(粉色)
// let path = '/service';
// 方案一(4个大按钮)
// let path = '/service-scheme-one';
//方案二
let path = '/service-scheme-two'
const routes = [
  {
    //默认首页
    path: '',
    redirect: path,
    meta: {
      //头部标题
      title: '掌上医院',
    },
  },
  {
    //首页方案二
    path: '/service-scheme-two',
    name: 'ServiceSchemeTwo',
    component: () => import('../views/service/ServiceSchemeTwo.vue'),
    meta: {
      //显示底部导航栏
      showTab: true,
      //切换动画
      level: 0,
      //头部标题
      title: '掌上医院',
    },
  },
  {
    //首页方案一
    path: '/service-scheme-one',
    name: 'ServiceSchemeOne',
    component: () => import('../views/service/ServiceSchemeOne.vue'),
    meta: {
      //显示底部导航栏
      showTab: true,
      //切换动画
      level: 0,
      //头部标题
      title: '掌上医院',
    },
  },
  {
    //首页
    path: '/service',
    name: 'service',
    component: () => import('../views/service/Service.vue'),
    meta: {
      //显示底部导航栏
      showTab: true,
      //切换动画
      level: 0,
      //头部标题
      title: '掌上医院',
    },
  },
  {
    //医院信息
    path: '/information',
    name: 'information',
    component: () => import('../views/information/information.vue'),
    meta: {
      showTab: true,
      level: 0,
      title: '医院',
    },
  },
  {
    //个人中心
    path: '/user',
    name: 'user',
    component: () => import('../views/user/User.vue'),
    meta: {
      showTab: true,
      level: 0,
      title: '掌上医院',
    },
  },
  {
    //门诊服务
    path: '/serve-list',
    component: () => import('../views/service/serve/ServeList.vue'),
    meta: {
      level: 1,
      title: '门诊服务',
    },
  },
  {
    //科室
    path: '/type',
    name: 'register',
    component: () => import('../views/service/department/department.vue'),
    meta: {
      level: 2,
      title: '选择科室',
    },
  },
  //选择医生
  {
    name: 'choiceDoctor',
    path: '/choice-doctor/:dept_name',
    component: () => import('../views/service/choiceDoctor/choiceDoctor.vue'),
    meta: {
      level: 3,
      title: '选择医生',
    },
  },
  //预约挂号确认
  {
    path: '/handle',
    name: 'handle',
    component: () => import('../components/common/handle/handle.vue'),
    meta: {
      level: 4,
      title: '确认信息',
    },
  },
  //预约成功页面
  {
    path: '/appointment-successful',
    name: 'appointmentSuccessful',
    component: () =>
      import('../views/user/appointmentRecord/appointmentSuccessful.vue'),
    meta: {
      level: 5,
      title: '预约成功',
    },
  },
  {
    //用户切换页面
    path: '/user-data',
    name: 'userData',
    component: () => import('../views/service/userData/userData.vue'),
    meta: {
      level: 5,
      title: '用户',
    },
  },
  {
    //预约记录
    path: '/appointment-record',
    component: () =>
      import('../views/user/appointmentRecord/appointmentRecord.vue'),
    meta: {
      level: 1,
      title: '预约记录',
    },
  },
  {
    //预约取消
    path: '/appointment-cancellation',
    name: 'appointmentCancellation',
    component: () =>
      import(
        '../views/user/appointmentCancellation/appointmentCancellation.vue'
      ),
    meta: {
      level: 2,
      title: '预约记录',
    },
  },
  {
    //挂号记录
    path: '/registration-record',
    component: () =>
      import('../views/user/registrationRecord/registrationRecord.vue'),
    meta: {
      level: 1,
      title: '挂号记录',
    },
  },
  {
    //挂号取消
    path: '/registration-cancellation',
    name: 'registrationCancellation',
    component: () =>
      import(
        '../views/user/appointmentCancellation/registrationCancellation.vue'
      ),
    meta: {
      level: 2,
      title: '挂号记录',
    },
  },
  {
    //支付记录查询
    path: '/payment-record-query',
    component: () =>
      import('../views/user/paymentRecordQuery/paymentRecordQuery.vue'),
    meta: {
      level: 1,
      title: '支付记录查询',
    },
  },
  {
    //登录页面
    path: '/login',
    name: 'login',
    component: () => import('../components/common/login/login.vue'),
    meta: {
      level: 6,
      title: '添加身份信息',
    },
  },
  {
    //化验单查询页面
    path: '/inspection-check',
    name: 'inspectionCheck',
    component: () =>
      import('../views/service/reportQuery/inspection-check.vue'),
    meta: {
      level: 2,
      title: '化验单查询',
    },
  },
  {
    //检验单详情页面
    path: '/inspectionSheet',
    name: 'inspectionSheet',
    component: () => import('../views/service/reportQuery/inspectionSheet.vue'),
    meta: {
      level: 3,
      title: '检验单详情',
    },
  },
  //住院费用查询
  {
    path: '/hospitalization-expenses',
    name: 'hospitalizationExpenses',
    component: () =>
      import(
        '../views/service/detailsHospitalization/hospitalizationExpenses.vue'
      ),
    meta: {
      level: 2,
      title: '住院费用',
    },
  },
  {
    //每日清单查询
    path: '/daily-list',
    name: 'dailyList',
    component: () => import('../views/service/dailyList/dailyList.vue'),
    meta: {
      level: 2,
      title: '每日清单',
    },
  },
  //住院预缴
  {
    path: '/hospitalization-prepayment',
    component: () =>
      import(
        '../views/service/detailsHospitalization/hospitalizationPrepayment.vue'
      ),
    meta: {
      level: 2,
      title: '住院预缴',
    },
  },
  {
    //就医点评
    path: '/comment',
    name: 'comment',
    component: () => import('../views/user/comment/comment.vue'),
    meta: {
      level: 1,
      title: '就医点评',
    },
  },
  {
    //点评详情页
    path: '/commentpage',
    name: 'commentpage',
    component: () => import('../views/user/comment/commentPage.vue'),
    meta: {
      level: 2,
      title: '就医点评',
    },
  },
  {
    //问卷调查
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('../views/user/questionnaire/questionnaire.vue'),
    meta: {
      level: 6,
      title: '问卷调查',
    },
  },
  {
    //问卷调查感谢页面
    path: '/complete',
    name: 'complete',
    component: () => import('../views/user/questionnaire/complete.vue'),
    meta: {
      level: 7,
    },
  },
  {
    //新闻
    path: '/news',
    name: 'news',
    component: () => import('../views/service/news/news.vue'),
    meta: {
      level: 1,
      title: '新闻',
    },
  },
  {
    //检查单详情页面
    path: '/checkList',
    name: 'checkList',
    component: () => import('../views/service/reportQuery/checkList.vue'),
    meta: {
      level: 3,
      title: '检查单详情',
    },
  },
  {
    //缴费
    path: '/pay',
    name: 'pay',
    component: () => import('../views/service/pay/pay.vue'),
    meta: {
      level: 1,
      title: '缴费',
    },
  },
  {
    //缴费明细
    path: '/payment-details',
    name: 'paymentDetails',
    component: () => import('../views/service/pay/paymentDetails.vue'),
    meta: {
      level: 2,
      title: '缴费明细',
    },
  },
  {
    //投诉建议
    path: '/complaint',
    name: 'complaint',
    component: () => import('../views/service/complaint/complaint.vue'),
    meta: {
      level: 1,
      title: '投诉建议',
    },
  },
  {
    //门诊费用
    path: '/outpatientExpenditure',
    name: 'outpatientExpenditure',
    component: () =>
      import(
        '../views/service/outpatientExpenditure/outpatientExpenditure.vue'
      ),
    meta: {
      level: 1,
      title: '门诊费用',
    },
  },
  {
    //门诊费用详情
    path: '/expenditureDetail',
    name: 'expenditureDetail',
    component: () =>
      import('../views/service/outpatientExpenditure/expenditureDetail.vue'),
    meta: {
      level: 1,
      title: '门诊费用详情',
    },
  },
  {
    //预约和挂号分类
    path: '/classification',
    name: 'classification',
    component: () =>
      import('../views/service/classification/classification.vue'),
    meta: {
      level: 1,
      title: '预约挂号',
    },
  },
  {
    //新闻列表
    path: '/detailnews',
    name: 'detailnews',
    component: () => import('../views/service/news/detailnews.vue'),
    meta: {
      level: 2,
      title: '新闻列表',
    },
  },
  {
    //药品查询
    path: '/drugs',
    name: 'drugs',
    component: () => import('../views/service/drugs/drugs.vue'),
    meta: {
      level: 1,
      title: '药品查询',
    },
  },
  {
    //药品信息
    path: '/drug-information',
    name: 'drugInformation',
    component: () => import('../views/service/drugs/drugInformation.vue'),
    meta: {
      level: 2,
      title: '药品信息',
    },
  },
  {
    //健康宣教
    path: '/health-education',
    name: 'healthEducation',
    component: () =>
      import('../views/service/healthEducation/healthEducation.vue'),
    meta: {
      level: 1,
      title: '健康宣教',
    },
  },
  {
    //健康宣教（内容）
    path: '/healthy/:id',
    name: 'healthy',
    component: () => import('../views/service/healthEducation/healthy.vue'),
    meta: {
      level: 2,
      title: '健康宣教',
    },
  },
  {
    //健康宣教（内容）
    path: '/zfsuccess',
    name: 'zfsuccess',
    component: () => import('../views/service/success/zfsuccess.vue'),
    meta: {
      level: 1,
      title: '支付成功',
    },
  },
  {
    //体检信息录入
    path: '/excessivePage',
    name: 'excessivePage',
    component: () =>
      import('../views/service/physicalExamination/excessivePage.vue'),
    meta: {
      level: 1,
      title: '体检信息录入',
    },
  },
  {
    //体检缴费
    path: '/physicalPay',
    name: 'physicalPay',
    component: () =>
      import('../views/service/physicalExamination/physicalPay.vue'),
    meta: {
      level: 2,
      title: '体检缴费',
    },
  },
  {
    //体检报告
    path: '/physicalReport',
    name: 'physicalReport',
    component: () =>
      import('../views/service/physicalReport/physicalReport.vue'),
    meta: {
      level: 1,
      title: '体检报告',
    },
  },
  {
    //体检报告
    path: '/physicalPDF',
    name: 'physicalPDF',
    component: () => import('../views/service/physicalReport/physicalPDF.vue'),
    meta: {
      level: 2,
      title: '体检报告PDF',
    },
  },
]
const router = new VueRouter({
  routes,
  mode: 'hash',
})
import { cardBindingQuery } from '@/network/service'
router.beforeEach((to, from, next) => {
  //判断是否存在openId
  let open_id = Vue.prototype.$Cookies.get()
  // let open_id = {open_id:'o-i9O52oKueBIgbVd0dMwb50CZQY'}
  if (Object.keys(open_id).length == 0) {
    let returnUrl
    let query
    if (Object.keys(to.query).length == 0) {
      returnUrl =
        process.env.NODE_ENV == 'development'
          ? `http://121.196.22.202/weChat/user/getPublicNoUserInfo&routeUrl=${to.path}`
          : `http://www.langfangfuyou.com/weChat/user/getPublicNoUserInfo&routeUrl=${to.path}`
    } else {
      query = Object.keys(to.query)
        .map((el, index) => el + '=' + Object.values(to.query)[index])
        .reduce((a, b) => a + '%26' + b)
      returnUrl =
        process.env.NODE_ENV == 'development'
          ? `http://121.196.22.202/weChat/user/getPublicNoUserInfo&routeUrl=${to.path}?${query}`
          : `http://www.langfangfuyou.com/weChat/user/getPublicNoUserInfo&routeUrl=${to.path}?${query}`
    }
    window.location.href =
      process.env.NODE_ENV == 'development'
        ? `http://121.196.22.202/weChat/user/authorize?returnUrl=${returnUrl}`
        : `http://www.langfangfuyou.com/weChat/user/authorize?returnUrl=${returnUrl}`
  } else {
    console.log(to.path)
    if (to.path != path && from.path == '/' && to.path != '/login') {
      cardBindingQuery({
        open_id: open_id.open_id,
      })
        .then(res => {
          if (res.code == '0') {
            store.state.cardList = res.data.sort((item, list) => {
              return list.updateTime > item.updateTime ? 1 : -1
            })
            if (store.state.cardList.length != 0) {
              store.state.headPortrait = '#icon-weidenglutouxiang'
              if (store.state.cardList.length != 0) {
                store.state.phoneState = store.state.cardList[0].phone
                //获取名字
                store.state.nameState = store.state.cardList[0].name
              }
              Vue.prototype.$valve = true
              next()
            } else {
              next('/login')
            }
          } else {
            Vue.prototype.$Toast(res.msg)
          }
        })
        .catch(err => {
          console.log(err)
          Vue.prototype.$Toast('获取绑卡列表失败')
        })
    }
    //判断是否登录账号
    if (store.state.cardList.length != 0) {
      document.title = to.meta.title
    } else {
      if (
        to.path == '/login' ||
        to.path == path ||
        to.path == '/information' ||
        to.path == '/user' ||
        to.path == '/serve-list' ||
        to.path == '/user-data'
      ) {
        next()
      } else if (from.path != '/') {
        next('/login')
      }
    }
    //刷新进入首页
    if (to.path == path || to.path == '/user' || to.path == '/information') {
      clearSession()
      Vue.prototype.$valve = true
      next()
    } else if (Vue.prototype.$valve) {
      next()
    } else if (from.path != '/') {
      next(path)
    }
  }
})
export default router
