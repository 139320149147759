import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import Cookies from 'js-cookie';
//1.安装插件
Vue.use(Vuex);

//2.创建store对象
const state = {
  //病案号
  med_id:'',
  //挂号方式
  reg_type: '',
  //菜单名
  menuName: '',
  //号别
  reg_class: '',
  //状态名称
  nameState: '未登录',
  //电话号码
  phoneState: '***********',
  //流水号
  seq: '',
  //用户信息
  cardList: [],
  //微信信息
  head: {},
  //科室代码
  dept_id: '',
  //功能模块
  functionalModule: [],
  //openId
  open_id: Cookies.get(),
  // open_id:{
  //   open_id:'o-i9O52oKueBIgbVd0dMwb50CZQY'
  // },
  //设置页码
  pageSet: 10,
  active: 1,
  //预约记录信息查询
  appointmentList: {
    page: 1,
    list: [],
  },
  //挂号记录信息查询
  registrationList: {
    page: 1,
    list: [],
  },
  //化验单页面是否加载
  adopt: false,
  //阀门
  valve: true,
  //头像
  headPortrait: '#icon-weidenglutouxiang',
  //院内服务功能
  typeList: [],
  //安卓系统问题(tabbar组件)
  hidshow: true,
};

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
});

//3.挂载Vue实例上
export default store;
