<template>
  <!-- 选择医生 -->
  <div class="choice-doctor">
    <div class="region-scroll" ref="scroll">
      <van-sidebar v-model="activeKey" @change="rankFilterChange">
        <van-sidebar-item
          v-for="(item, index) in filterRankList"
          :key="index"
          :title="item"
        />
      </van-sidebar>
      <!-- 医生 -->
      <div class="doctor-common">
        <div class="doctor">
          <div
            class="doctor-card"
            v-for="(item, indexs) in doctorList"
            :key="indexs"
          >
            <div class="doctor-information">
              <div class="doctor-head-portrait">
                <img
                  :src="
                    item.photo.length == 0
                      ? require('@/static/img/tou.webp')
                      : item.photo
                  "
                  alt=""
                />
              </div>
              <!-- 医生个人信息 -->
              <div class="doctor-name">
                <span class="name">
                  <span class="in-size">
                    {{
                      item.doc_name.length == 0 ? item.dept_name : item.doc_name
                    }}
                    <span class="small-size">
                      ({{ doc_rank[item.treat_type] }})
                    </span>
                  </span>
                  <div class="small-size">
                    {{ item.ybmsg }}
                  </div>
                </span>
              </div>
            </div>
            <div v-if="item.deptIntro" class="doctor-introduce small-size line">
              <div
                :class="item.status ? '' : 'introduce'"
                @click="foldIntro(indexs)"
              >
                <div>
                  <span>科室简介：</span>
                  {{ item.deptIntro ? item.deptIntro : '--' }}
                </div>
              </div>
            </div>
            <!-- 医生介绍 -->
            <div class="doctor-introduce small-size line">
              <span>
                {{
                  item.docSuperiority.length == 0
                    ? '暂无医生介绍'
                    : item.docSuperiority
                }}
              </span>
            </div>
            <!-- 医生排班 -->
            <div class="doctor-time in-size">
              <van-cell
                class="cell"
                :title="title_fn(item)"
                is-link
                @click="skip(item)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 号源 -->
    <van-action-sheet
      ref="sheetTwo"
      class="sheet"
      v-model="signal"
      :round="false"
      :closeable="false"
      :title="yuHao"
    >
      <div v-if="signalList.length != 0" class="signal-source">
        <div
          v-for="(itemTwo, index) in signalList"
          :key="index"
          class="signal-source-item"
        >
          <div class="signal" @click="signalSourceClick(itemTwo)">
            <span>余： {{ itemTwo.rest_num }}</span>
            <span>
              {{
                itemTwo.visit_time.split('-')[0] +
                  '~' +
                  itemTwo.visit_time.split('-')[1]
              }}
            </span>
          </div>
        </div>
      </div>
      <van-empty v-else image="search" description="暂无号源" />
    </van-action-sheet>
  </div>
</template>

<script>
import { doctorPlan, registerAssignedNumber } from 'network/service'
// import { doctorPlan } from 'network/service'
import { mapState } from 'vuex'
import { fun_date } from '../../../common/utils'
import { photoArr, deptArr } from './doctorImgList'
// import { getTimeNoSecond } from '../../../common/utils'
export default {
  name: 'choiceDoctor',
  data() {
    return {
      activeKey: 0,
      filterRankList: [],
      //医生数组
      doctorList: [],
      allDoctorList: [],
      docItem: {},
      doc_rank: {
        '0': '普通医生',
        '001': '一般医师',
        '002': '主治医师',
        '003': '副主任医师',
        '004': '主任医师',
        '005': '专家',
        '006': '免费',
        '007': '京津专家',
        '008': '急诊',
        '009': '特殊疾病',
        '010': '主任中医师',
        '011': '副主任中医师',
        '012': '中医师',
        '013': '简易门诊',
      },
      //号源对应时间（需同步修改预约记录中的对应关系）
      shiftTime: {
        '1': '全天',
        '2': '06:00-12:00',
        '3': '14:30-17:00',
        '20': '08:00-08:30',
        '21': '08:30-09:00',
        '22': '09:00-09:30',
        '23': '09:30-10:00',
        '24': '10:00-10:30',
        '25': '10:30-11:00',
        '26': '11:00-11:30',
        '27': '11:30-12:00',
        '28': '14:30-15:00',
        '29': '15:00-15:30',
        '30': '15:30-16:00',
        '31': '16:00-16:30',
        '32': '16:30-17:00',
        '33': '14:00-14:30',
        '36': '17:00-17:30',
      },
      visitdate: '',
      order_date: '',
      nowTime: '',
      //显示号源
      signal: false,
      //号源信息
      signalList: [],
    }
  },
  computed: {
    ...mapState(['reg_type', 'seq', 'reg_class', 'dept_id', 'id']),
    yuHao() {
      // return `时间:${this.order_date}\n余号:${this.signalList.length}`
      return `时间:${this.order_date}\n请选择号源`
    },
  },
  mounted() {
    console.log(this.$route.query)
    this.order_date = this.$route.query.order_date
    this.doctorPlanPost()
  },
  methods: {
    foldIntro(idx) {
      const isFold = !this.doctorList[idx].status
      this.$set(
        this.doctorList,
        idx,
        Object.assign(this.doctorList[idx], { status: isFold })
      )
    },
    async doctorPlanPost() {
      if (this.$route.query.order_date == '当天') {
        let y = new Date().getFullYear()
        let m = new Date().getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = new Date().getDate()
        d = d < 10 ? '0' + d : d
        this.order_date = y + '-' + m + '-' + d
        this.nowTime = new Date().getHours() + ':' + new Date().getMinutes()
      }
      let res = await doctorPlan({
        treat_type: '01',
        treat_mode: '1',
        shift: '0',
        dept_id: this.dept_id,
        dept_name: this.$route.query.dept_name,
        treat_date: this.order_date,
      })
      if (res.code == '0') {
        if (
          this.$route.query.order_date == '当天' &&
          this.nowTime.split(':')[0] >= 12
        ) {
          this.allDoctorList = res.data.root.body.detail.filter(el => {
            // if (el.dept_name == '中医科') {
            //   return (
            //     el.treat_type != '006' &&
            //     el.treat_type != '008' &&
            //     el.rest_num != 0 &&
            //     el.treat_type != '012'
            //   )
            // } else {
            //   return (
            //     el.treat_type != '006' &&
            //     el.treat_type != '008' &&
            //     el.rest_num != 0
            //   )
            // }
            return (
              el.treat_type != '006' &&
              el.treat_type != '008' &&
              el.rest_num != 0
            )
          })
        } else {
          this.allDoctorList = res.data.root.body.detail.filter(el => {
            if (el.dept_name == '中医科') {
              let nowHour = new Date().getHours()
              if (
                this.$route.query.order_date == fun_date(1)[1] &&
                nowHour >= 16
              ) {
                return el.treat_type != '006' && el.treat_type != '008'
              } else {
                return (
                  el.treat_type != '006' &&
                  el.treat_type != '008' &&
                  el.rest_num != 0 &&
                  el.treat_type != '012'
                )
              }
            } else {
              return (
                el.treat_type != '006' &&
                el.treat_type != '008' &&
                el.rest_num != 0
              )
            }
          })
        }

        this.allDoctorList.forEach(el => {
          el.photo = ''
          el.docSuperiority = ''
          el.docIntroduce = ''
          if (el.treat_type == '001') {
            el.status = false
            el.deptIntro = deptArr[el.dept_name]
          }
          photoArr.forEach(element => {
            if (el.doc_name == element.docName) {
              if (element.havePhoto) {
                el.photo = require('@/static/img/doctorZP/' +
                  el.doc_name +
                  '.jpg')
              }
              el.docSuperiority = element.superiority
              el.docIntroduce = element.introduce
            }
          })
          this.filterRankList.includes(this.doc_rank[el.treat_type])
            ? ''
            : this.filterRankList.push(this.doc_rank[el.treat_type])
        })

        this.rankFilterChange()
      } else {
        this.$toast(res.msg)
      }
    },
    rankFilterChange() {
      for (let key in this.doc_rank) {
        if (this.doc_rank[key] == this.filterRankList[this.activeKey]) {
          this.doctorList = this.allDoctorList.filter(
            el => el.treat_type == key
          )
        }
      }

      this.doctorList = this.doctorList
        .filter(el => {
          if (
            (el.dept_name == '生长发育科' || el.dept_name == '身高管理科') &&
            el.treat_type == '003' &&
            el.doc_name == '张庆菊'
          ) {
            return false
          } else {
            return true
          }
        })
        .sort((a, b) => {
          if (a.doc_name < b.doc_name) {
            return -1
          } else if (a.doc_name > b.doc_name) {
            return 1
          }
        })
    },
    async skip(item) {
      if (
        this.$route.query.order_date == '当天' &&
        this.isShowAssignedNumberHandle(item.treat_type, item.dept_name)
      ) {
        return
      }
      if (item.rest_num == 0) {
        this.$toast('无余号，无法挂号')
        return
      }
      this.docItem = item
      let res = await registerAssignedNumber({
        dept_id: item.dept_id,
        order_id: item.order_id,
        treat_mode: this.$route.query.order_date == '当天' ? 1 : 2,
        treat_type: item.treat_type,
        treat_date: this.order_date,
        shift: '0',
      })
      if (res.code == 0) {
        this.signal = true
        this.signalList = res.data.root.body.detail
          .filter(item => {
            if (this.$route.query.order_date == '当天' && item.shift != '1') {
              return this.isExceedTime(this.shiftTime[item.shift])
            } else {
              return true
            }
          })
          .filter(item => item.shift != '80' && item.shift != '81')
        this.signalList = this.signalList.sort((a, b) => {
          const order = [
            '2',
            '3',
            '1',
            '20',
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '33',
            '28',
            '29',
            '30',
            '31',
            '32',
            '36',
          ]
          return order.indexOf(a.shift) - order.indexOf(b.shift)
        })
      } else {
        this.$toast(res.msg)
      }
    },
    //号源显示处理
    isShowAssignedNumberHandle(treatType, deptName) {
      let hour = new Date()
        .getHours()
        .toString()
        .padStart(2, '0')
      let minute = new Date()
        .getMinutes()
        .toString()
        .padStart(2, '0')
      let time = hour + ':' + minute
      //中医科号源特殊处理
      if (
        deptName == '中医科' &&
        !this.isInTimeSlot(['07:30-12:00', '13:30-17:00'], time)
      ) {
        return true
      } else {
        return false
      }
    },
    isInTimeSlot(timeArr, now) {
      let result = false
      timeArr.forEach(el => {
        if (now > el.split('-')[0] && now < el.split('-')[1]) {
          result = true
        }
      })
      return result
    },
    //号源跳转
    signalSourceClick(item) {
      this.$router.push({
        path: '/handle',
        query: {
          item: this.docItem,
          // dept_name:item.dept_name,
          // doc_name:item.doc_name,
          visitdate: this.visitdate,
          timeslot: item.visit_time,
          shift: item.shift,
          reg_type: this.$route.query.flag,
        },
      })
    },
    title_fn(item) {
      if (this.$route.query.order_date == '当天') {
        let y = new Date().getFullYear()
        let m = new Date().getMonth() + 1
        m = m < 10 ? '0' + m : m
        let d = new Date().getDate()
        d = d < 10 ? '0' + d : d
        this.order_date = y + '-' + m + '-' + d
      }
      let order_date = this.order_date
      let weekArray = new Array('日', '一', '二', '三', '四', '五', '六')
      let week = '周' + weekArray[new Date(order_date).getDay()]
      let sworxw = this.shiftTime[item.shift] ? this.shiftTime[item.shift] : ''
      this.visitdate = order_date
      return (
        order_date +
        '  ' +
        week +
        '  ' +
        sworxw +
        '\xa0\xa0\xa0\xa0\xa0' +
        '余号' +
        item.rest_num
      )
    },
    isExceedTime(time) {
      if (!time) {
        return false
      }
      let hour = new Date()
        .getHours()
        .toString()
        .padStart(2, '0')
      let minute = new Date()
        .getMinutes()
        .toString()
        .padStart(2, '0')
      let now = hour + ':' + minute
      return time.split('-')[1] > now ? true : false
    },
  },
}
</script>

<style lang="less" scoped>
.choice-doctor {
  background-color: rgb(245, 245, 245);
}
.line {
  border-bottom: 1px solid #f5f5f5f5;
}
.sheet {
  height: 50vh;
  white-space: pre-line;
}
/deep/.van-action-sheet__header {
  line-height: 2rem;
}
/deep/.van-action-sheet__content {
  .signal-source {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    width: 99vw;
    margin: auto;
    .signal {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23vw;
      white-space: nowrap;
      padding: 0.7rem 0.3rem;
      border-radius: 0.7rem;
      border: 1px solid @lineColor;
      line-height: 1.5rem;
      box-sizing: border-box;
      font-size: 0.8rem;
      margin: 0.2rem;
    }
  }
}
.region-scroll {
  width: 100%;
  height: 100vh;
  display: flex;
  ::v-deep.van-sidebar-item {
    padding: 0.75rem;
  }
  .doctor-common {
    width: calc(100% - 5rem);
    height: 100%;
    overflow: auto;
    .doctor {
      width: 90%;
      margin: 1rem auto;
      .doctor-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 0.5rem;
        background-color: rgb(255, 255, 255);
        margin-top: 1rem;
        .doctor-information {
          display: flex;
          align-items: center;
          padding: 0.5rem 0;
          box-sizing: border-box;
          .doctor-head-portrait {
            margin: 0 1rem;
            height: 4.5rem;
            width: 4rem;
            min-width: 4rem;
            img {
              border-radius: 50%;
              height: 100%;
              width: 100%;
            }
          }
          .doctor-name {
            .name {
              display: flex;
              flex-direction: column;
              > span {
                line-height: 1.5rem;
                padding-right: 1rem;
              }
            }
          }
        }
        .doctor-introduce {
          padding: 0.5rem 1rem 0.5rem 1rem;
          box-sizing: border-box;
          border-bottom: 1px solid rgb(245, 245, 245);
          // 遮罩层
          .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          }
          .wrapper-introduce {
            border-radius: 1rem;
            width: 80vw;
            padding: 1rem;
            background-color: rgb(255, 255, 255);
            line-height: 1.3rem;
          }
          .introduce {
            display: -webkit-box; /*将对象转为弹性盒模型展示*/
            -webkit-box-orient: vertical; /*设置弹性盒模型子元素的排列方式*/
            -webkit-line-clamp: 2; /*限制文本行数*/
            overflow: hidden; /*超出隐藏*/
          }
        }
        .doctor-time {
          /deep/.van-cell {
            border-radius: 1rem;
          }
        }
      }
    }
  }
}
</style>
